.hero-wrapper {
  width: 100%;
  // margin: 0 auto;
  // height: calc(100vh - 155px);
  aspect-ratio: 16/13;
  display: flex;
  flex-direction: column;
  // max-width: 55%;
  position: relative;
  // overflow: hidden;

  .loader {
    position: absolute;
    bottom: 50px;
    right: 20px;
    width: 24px;
    height: 24px;
    border: 3px solid #7a7a7a;
    border-top-color: transparent;
    background: transparent;
    border-radius: 50%;
    animation: rot 2s infinite;
    
    @keyframes rot {
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .logo {
    position: relative;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out ;
    a {
      text-decoration: none;
      background: transparent;
      width: max-content;
      transition: opacity 0.3s ease-in-out;
      .logo1 {
        background: transparent;
        list-style: none;
        position: relative;
        transition: all 1.2s ease-in;
        margin-left: 2rem;
        width: max-content;
        overflow: hidden;
        position: relative;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
  .navbar-content {
    padding-right: 16px;
    .about-nav {
      display: none;
    }
    .moon-nav {
      display: none;
    }
    .nav {
      transition: color 0.72s ease-in-out;
      &.active {
        p {
          color: black;
        }
      }
      &:hover {
        color: black;
      }
      a {
        color: #d9d9d9;
        text-decoration: none;
        &:hover {
          color: black;
        }
      }
    }
  }
  .about-wrapper {
    position: relative;
    .hero-box {
      position: relative;
      width: 100%;
      aspect-ratio: 16/9;
      // background: #d9d9d9;
      border-radius: 5px;
      border: 1px solid #5835214d;
      background-image: url("../../assets/images/Hero.svg");
      background-size: 100% 100%;
      cursor: pointer;
      overflow: hidden;
      
      .hero-box-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../assets/images/Hand.svg);
        background-repeat: no-repeat;
        background-position: top left;
        background-size: cover;
      }
      
      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        
        .flex {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 7px;
          background: transparent;
          
          img {
            height: 30px;
            object-fit: contain;
            width: fit-content;
            background: transparent;
          }
        }
        
        .hand {
          width: 280px;
          animation: fadeIn 6s;
          background: transparent;
        }
        // @keyframes fadeIn {
          //   0% {
            //     opacity: 0;
            //   }
            //   25% {
              //     opacity: 0;
              //   }
              //   100% {
                //     opacity: 1;
                //   }
                // }
                .way {
                  width: 180px;
                  animation: fadeOut 9s;
                  background: transparent;
                }
              }
            }
            .request {
              padding-top: 10px;
              text-align: center;
              letter-spacing: 1.5px;
              color: #c9c9c9;
              font-family: "Walkway-Black";
              font-size: 8px;
              p {
                text-decoration: none;
              }
            }
            .loading-content {
              background: transparent;
              position: absolute;
              bottom: 50px;
              right: 20px;
            }
          }
          
          .work-box {
            position: relative;
            width: 100%;
            aspect-ratio: 16/9;
            border-radius: 4px;
            border: 1px solid #5835214d;
            align-items: center;
            cursor: pointer;
            overflow: hidden;
            margin-top: 16px;
            display: grid;
            grid-template-columns: 1fr 4fr;
            .box1 {
              height: 100%;
              position: relative !important;
              overflow: hidden;
              .rotator {
                position: absolute;
                left: -3450px;
                width: max-content;
                transform-origin: center;
                transform: rotate(-90deg);
                // border: 1px solid rosybrown;
                background: transparent;
                display: flex;
                flex-direction: column;
                gap: 2px;
                overflow: hidden;
        .services {
          background: transparent;
          margin-top: 12px;
          .text {
              white-space: nowrap;
              background: transparent;
              color: #C0C0C0;
              overflow: hidden;
              .text1 {
                  background: transparent;
                  animation: slide1 24s infinite linear;
                  display: inline-block;
                  text-align: right;
                  font-family: "Walkway-Bold";
                  font-size: 26px;
                  font-weight: 400;
                  font-style: normal;
                  text-transform: uppercase;
                  letter-spacing: 2.7px;
              }
          }
        }
        .testimonials {
          background: transparent;
          margin-bottom: 20px;
          .text {
              white-space: nowrap;
              background: transparent;
              color: #E4E4E4;
              overflow: hidden;
              .text1 {
                  background: transparent;
                  animation: slide2 20s linear infinite;
                  display: inline-block;
                  text-align: right;
                  font-family: "Walkway-Black";
                  font-size: 52px;
                  font-weight: 400;
                  font-style: normal;
                  text-transform: uppercase;
                  letter-spacing: 4px;
              }
          }
        }
      }
    }
    .box2 {
      width: 100%;
      height: 100%;
      z-index: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 32px 0 16px;
      video {
          width: 100%;
          object-fit: cover;
          border-radius: 4px;
      }
    }
    .box3 {
      display: none;
    }
  }
  .request {
    padding-top: 10px;
    text-align: center;
    letter-spacing: 1.5px;
    color: #c9c9c9;
    font-family: "Walkway-Black";
    font-size: 8px;
  }
  .request1 {
    background: transparent;
  }
}

@keyframes slide1 {
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(-100%);
  }
}
@keyframes slide2 {
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(100%);
  }
}


@media screen and (max-width: 430px) {
  .hero-wrapper {
    justify-content: center;
    height: 70dvh;
    .header-wrapper {
      .navbar-content {
        .nav {
          p {
            font-size: 0.8rem;
          }
        }
      }
    }
    .loader {
      bottom: 58px;
      right: 18px;
      width: 20px;
      height: 20px;
      border: 2.8px solid #7a7a7a;
    }
    .about-wrapper {
      height: 100%;
      .hero-box {
        height: 90%;
        .hero-box-bg {
          
        }
      }
      .request {
        padding-top: 8px;
        letter-spacing: 1px;
        font-size: 7px;
      }
      .loading-content {
        bottom: 58px;
        right: 18px;
      }
    }
    .request {
      padding-top: 8px;
      letter-spacing: 1px;
      font-size: 7px;
    }


    .box-wrapper {
      height: 100%;
      .work-box {
        height: 90%;
        margin-top: 6px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr 1fr;
        .box1 {
          .rotator {
            position: relative;
            transform: rotate(0deg);
            .desktop-testimonials {
              display: none;
            }
          }
        }
        .mob-box {
          display: grid;
          align-items: end;
          .rotator {
            .services {
              margin-top: 0px;
              padding-bottom: 12px;
              .text {
                .text1 {
                  font-size: 30px;
                }
              }
            }
          }
        }

        .box2 {
          padding: 0 4px;
        }
        .box3 {
          display: block;
        }
      }
    }
    .mob-blog-home {
      height: 100%;
    }
  }
}

@media screen and (max-width: 375px) {
  .hero-wrapper {
    .about-wrapper {
      .hero-box {
        .hero-box-bg {
          .text {
            right: 15px;
            .hand {
              width: 246px;
            }
            .way {
              height: 28px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .hero-wrapper {
    .header-wrapper {
      .navbar-content {
        gap: 20px;
        .nav {
          p {
            font-size: 0.7rem;
          }
        }
      }
    }
    .loader {
      bottom: 36px;
      right: 12px;
      width: 20px;
      height: 20px;
      border: 2.8px solid #7a7a7a;
    }
    .about-wrapper {
      .hero-box {
        .hero-box-bg {
          .text {
            right: 12px;
            gap: 4px;
            .hand {
              width: 212px;
            }
            .way {
              height: 24px;
            }
          }
        }
      }
      .request {
        font-size: 6px;
      }
      .loading-content {
        bottom: 36px;
        right: 12px;
      }
    }
    .request {
      font-size: 6px;
    }
  }
}
.cursor {
  background-color: #3c3c3c;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .cursor-text {
      background: transparent;
      text-align: center;
      font-size: 0.7rem;
      color: #fff;
      letter-spacing: 0.9px;
      text-transform: uppercase;
  }
}

@media screen and (max-width: 768px) {
  .cursor {
    display: none;
  }
}
.BlogPost-wrapper {
    aspect-ratio: 16/12;
    position: relative;
    padding: 10% 20px 0px 20px;
    max-width: 55%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .header-blog {
        margin-bottom: 7rem;
        justify-content: center;
        gap: 28px;
        .navbar-content {
            .nav {
                &.active {
                    p {
                        color: black;
                    }
                }
                &:hover {
                    color: black;
                }
                a {
                    color: #d9d9d9;
                    text-decoration: none;
                    &:hover {
                        color: black;
                    }
                }
            }
        }
    }
    .BlogPostContent {
        height: 120dvh;
        .title {
            display: flex;
            justify-content: center;
            margin-bottom: 0.88rem;
            h1 {
                width: 90%;
                text-align: center;
                color: var(--GoatHoof, #461F09);
                font-family: DM Serif Display;
                font-size: 3.1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.15rem;
                text-transform: capitalize;
                position: relative;
            }
        }
        .static-page {
            position: sticky;
            position: -webkit-sticky;
            top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 3.5rem;
            .topic {
                margin-bottom: 2.1rem;
                width: 55%;
                height: 1rem;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                text-align: center;
                .topic-name {
                    letter-spacing: 0.10rem;
                    margin-bottom: 0.5rem;
                    font-family: Walkway-Black;
                    font-size: 1.05rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: uppercase;
                    color: #000;
                    background: transparent;
                }
            }
            .scrolled-content {
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 60%;
                .content {
                    display: inline-flex;
                    height: 80dvh;
                    flex-direction: column;
                    align-items: center;
                    border: 1px solid #D0D0D0;
                    overflow: hidden;
                    border-radius: 5px;
                    .paragraphs {
                        scroll-behavior: smooth;
                        padding: 2.5rem 1.5rem 0;
                        height: 100%;
                        transition: 1.2s ease;
                        -ms-overflow-style: none; //IE AND EDGE
                        scrollbar-width: none;  //FIREFOX
                        &:hover {
                            box-shadow: inset 0px 15px 23px -14px rgba(0, 0, 0, 0.3);
                        }
                        .paragraph {
                            color: var(--black-color);
                            // font-family: walkway-Bold;
                            font-family: 'Open Sans', sans-serif;
                            letter-spacing: -0.03rem;
                            // letter-spacing: 0.06rem;
                            font-style: normal;
                            font-size: 0.98rem;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 3rem;
                            background: transparent;
                            text-align: justify;
                            strong {
                                font-family: 'Open Sans', sans-serif;
                                letter-spacing: -0.03rem;
                                background: transparent;
                            }
                            ul {
                                background: transparent;
                                text-decoration: none;
                                li {
                                    background: transparent;
                                    font-family: 'Open Sans', sans-serif;
                                    letter-spacing: -0.03rem;
                                    text-decoration: no;
                                }
                            }
                        }
                        &::-webkit-scrollbar {
                            display: none;  //CHROME, SAFARI, OPERA
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1440px) {
        max-width: 750px;
    }
}
.backToBlog-wrapper {
    margin-top: 0.8rem;
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    align-items: flex-end;
    a {
        text-decoration: none;
        width: max-content;
        .rectangle {
            border: 1px solid rgb(208, 208, 208, 0.4);
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            padding: 4px 8px;
            .text {
                opacity: 0.4;
                transition: opacity 0.7s ease-in-out;
                .main-text {
                    span {
                        color: #977D6F;
                        text-align: right;
                        font-family: "Walkway-Black";
                        font-size: 10px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                    }
                }
                .back-text {
                    span {
                        color: #A7A7A7;
                        text-align: right;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 12px;
                        letter-spacing: -0.72px;
                    }
                }
            }
            .icon {
                transition: opacity 0.7s ease-in-out;
                opacity: 0.4;
            }
        }
        &:hover {
            .rectangle {
                .text {
                    opacity: 1;
                }
                .icon {
                    opacity: 1;
                }
            }
        }
        &:active {
            .rectangle {
                border: 1px solid rgb(208, 208, 208, 1);
            }
        }
    }
}

@media screen and (max-width: 430px) {
    .BlogPost-wrapper {
        padding: 6% 12px 0 12px;
        .header-blog {
            margin-bottom: 5rem;
            gap: 24px;
            .logo {
                a {
                    text-decoration: none;
                    .logo1 {
                        width: 78px;
                    }
                }
            }
            .blogNavbar-content {
                .nav {
                    a {
                        p {
                            font-size: 0.75rem;
                        }
                    }
                }
            }
        }
        .BlogPostContent {
            .title {
                h1 {
                    font-size: 2.4rem;
                    letter-spacing: -0.1rem;
                }
            }
            .static-page {
                margin-top: 2.5rem;
                .topic {
                    width: 92%;
                    .topic-name {
                        font-size: 0.92rem;
                    }
                }
                .scrolled-content {
                    width: 92%;
                    .content {
                        .paragraphs {
                            padding: 2rem 1rem 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .BlogPost-wrapper {
        .BlogPostContent {
            .static-page {
                .scrolled-content {
                    .content {
                        .paragraphs {
                            .paragraph {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
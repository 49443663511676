.BlogPage-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .logo {
        opacity: 0.5;
        position: relative;
        transition: opacity 0.3s ease-in-out ;
        a {
          text-decoration: none;
          background: transparent;
          width: max-content;
          transition: opacity 0.3s ease-in-out;
          .logo1 {
            background: transparent;
            list-style: none;
            position: relative;
            transition: all 1.2s ease-in;
            margin-left: 2rem;
            width: max-content;
            overflow: hidden;
            position: relative;
          }
        }
        &:hover {
          opacity: 1;
        }
    }
    .navbar-content {
        padding-right: 16px;
        .about-nav {
          display: none;
        }
        .moon-nav {
          display: none;
        }
        .nav {
          &.active {
            p {
              color: black;
            }
          }
          &:hover {
            color: black;
          }
          a {
            color: #d9d9d9;
            text-decoration: none;
            &:hover {
              color: black;
            }
          }
        }
      }
    .BlogPost {
        position: relative;
        width: 100%;
        aspect-ratio: 16/9;
        // background: #d9d9d9;
        border-radius: 5px;
        border: 1px solid #5835214d;
        overflow-y: auto;
        // max-width: 25rem;
        padding: 24px 32px;
        // margin: 0 auto;
        margin-top: 16px;
        -ms-overflow-style: none; //IE AND EDGE
        scrollbar-width: none;  //FIREFOX
        .post {
            margin-bottom: 5rem;
            .link {
                text-decoration: none;
                .title {
                    padding-bottom: 0.7rem;
                    h3 {
                        opacity: 0.5;
                        color: var(--blogpageHeading-goathoof-color);
                        font-family: DMSerifDisplay-Regular;
                        font-size: 1.85rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                        text-decoration: none;
                        transition: 1.2s ease;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* number of lines to show */
                                line-clamp: 1;
                        -webkit-box-orient: vertical;
                        transition: opacity 0.4s ease-in-out;
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
            .summary {
                opacity: 0.8;
                color: var(--black-color);
                font-family: 'Open Sans', sans-serif;
                // letter-spacing: 0.05rem;
                letter-spacing: -0.02rem;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              .info {
                margin-top: 0.75rem;
                display: flex;
                flex-direction: row;
                gap: 1.25rem;
                p {
                    opacity: 0.8;
                    color: var(--black-color);
                    // font-family: Walkway-Bold;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 0.7rem;
                    letter-spacing: -0.03rem;
                    // letter-spacing: 0.03rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    // &:hover {
                    //     text-decoration: underline;
                    // }
                    .info-icon {
                        transform: translateY(1px);
                    }
                }
            }
        }
        &::-webkit-scrollbar {
          display: none;  //CHROME, SAFARI, OPERA
        }
    }
}


@media screen and (max-width: 430px) {
  .BlogPage-wrapper {
    height: 100%;
    .navbar-content {
      .nav {
        a {
          p {
            font-size: 0.8rem;
          }
        }
      }
    }
    .BlogPost {
      margin-top: 6px;
      height: 90%;
      padding: 20px 18px;
      .post {
        margin-bottom: 3rem;
        .link {
          .title {
            margin-bottom: 0.4rem;
            h3 {
              font-size: 1.5rem;
            }
          }
        }
        .summary {
          font-size: 0.7rem;
          text-align: justify;
        }
        .info {
          margin-top: 0.42rem;
          gap: 1rem;
        }
      }
    }
    .BlogPostWBlog {
      margin-top: 6px;
      height: 84%;
      padding: 20px 18px;
      .post {
        margin-bottom: 3rem;
        .link {
          .title {
            margin-bottom: 0.4rem;
            h3 {
              font-size: 1.5rem;
            }
          }
        }
        .summary {
          font-size: 0.7rem;
          text-align: justify;
        }
        .info {
          margin-top: 0.42rem;
          gap: 1rem;
        }
      }
    }
  }
}
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Open+Sans:wght@400;500&display=swap');

@font-face {
  font-family: "Walkway-Black";
  src: url("../src//assets/fonts/Walkway_Black.ttf");
}
@font-face {
  font-family: "Walkway-Bold";
  src: url("../src//assets/fonts/Walkway_Bold.ttf");
}
@font-face {
  font-family: "Walkway-SemiBold";
  src: url("../src//assets/fonts/Walkway_SemiBold.ttf");
}
@font-face {
  font-family: "Walkway-UltraBold";
  src: url("../src//assets/fonts/Walkway_UltraBold.ttf");
}
@font-face {
  font-family: "DMSerifDisplay-Regular";
  src: url("../src//assets/fonts/DMSerifDisplay-Regular.ttf");
}

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Walkway-Black";
  background: #fffbf6;
  cursor: none;
}

section {
  cursor: none;
  height: 100dvh;
  /* overflow: hidden; */
  scroll-snap-align: start;
}

.main {
 
}
.app {
  padding: 10px 20px;
  height: 100dvh;
  max-width: 55%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footerish {
  position: relative;
  margin: 0 auto;
  max-width: 55%;
}

.completeBlog-wrapper {
  height: 70dvh;
}

.contact {
  width: 100%;
}

@media screen and (max-width: 768px) {
  * {
    cursor: default;
  }
  section {
    cursor: default;
  }
}

@media (max-width: 1440px) {
  .app, .footerish {
    max-width: 750px;
  }
}


:root {
  /* ================== COLORS ================== */
  --bg-color: #fffbf6;
  --mainpage-smalltext-color: #c9c9c9;
  --mainpage-impsmalltext-color: #7a7a7a;
  --inactive-text: #d9d9d9;
  --black-color: #000;
  --herobox-border-color: #5835214d;
  --blogpageHeading-goathoof-color: #461f09;
}
.connect-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
  .connect-container {
    .up-img {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 180px 0 10px;
      background: transparent;
      svg {
          background: transparent;
          stroke: #B3B0AC;
      }
    }
    .connect-content {
      display: flex;
      align-items: center;
      gap: 113px;
      margin-top: 180px;
      .line {
        border-left: 2px solid #7a7a7a;
        height: 34px;
        margin-top: -30px;
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          font-size: 18px;
          color: #c9c9c9;
          letter-spacing: -0.351px;
          font-family: "DM Serif Display", serif;
        }
        h5 {
          margin: 8px 0;
          color: #7a7a7a;
          letter-spacing: 1.067px;
          font-size: 13px;
          text-align: center;
        }
        .icon {
          display: flex;
          gap: 13px;
          a {
            text-decoration: none;
            size: 0;
            margin: 0;
            padding: 0;
            display: flex;
          }
          img {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .connect-wrapper {
    .connect-container {
      .up-img {
        svg {
          width: 36px;
        }
      }
      .connect-content {
        gap: 32px;
        .line {
          margin-top: -8px;
          height: 24px;
        }
        .content {
          p {
            font-size: 16px;
          }
          h5 {
            font-size: 11px;
            margin: 6px 0;
          }
        }
        .icon {
          gap: 12px;
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .connect-wrapper {
    .connect-container {
      .up-img {
        svg {
          width: 24px;
        }
      }
      .connect-content {
        gap: 24px;
        margin-top: 100px;
        .line {
          margin-top: -8px;
          height: 16px;
        }
        .content {
          p {
            font-size: 14px;
          }
          h5 {
            font-size: 9px;
            margin: 5px 0;
          }
        }
        .icon {
          gap: 6px;
        }
      }
    }
  }
}
.footer-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 16px 20px;
  .footer-container {
    width: 100%;
    border-radius: 4px;
    transition: border 0.7s ease-in;
    background: #FFFCF6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    transition: all 0.32s ease-in;
    opacity: 0.5;
    .arrow {
      height: 100%;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      .main-arrow {
        background: transparent;
        width: 12px;
        // color: #4A3020;
      }
    }
  }
  &:hover {
    .footer-container {
      background: #FFF6E5;
      opacity: 0.8;
    }
  }
  &:active {
    .footer-container {
      border: 1.2px solid #D9CFC6;
    }
  }
}

@media screen and (max-width: 430px) {
  .footer-wrapper {
    padding: 0 8px 10px;
    .footer-container {
      padding: 2px 0;
      .arrow {
        .main-arrow {
          width: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .footer-wrapper {
    padding: 0 4px 5px;
    .footer-container {
      padding: 1px 0;
      .arrow {
        .main-arrow {
          width: 6px;
        }
      }
    }
  }
}
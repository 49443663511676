.verify-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  .verify-container {
    .linktohome {
      .aa-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 120px 0 10px;
        svg {
            width: 72px;
        }
      }
    }
    .verify-content {
      display: flex;
      align-items: center;
      gap: 72px;
      margin-top: 172px;
      .line {
        border-left: 2px solid #7a7a7a;
        height: 34px;
        margin-top: -4px;
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        p {
          font-size: 28px;
          color: #c9c9c9;
          letter-spacing: -0.351px;
          font-family: "DM Serif Display", serif;
          margin-bottom: 0.75rem;
        }
        .input {
          display: flex;
          width: 310px;
          position: relative;
          align-items: center;
          margin-bottom: 0.48rem;
          .input-email {
            outline: none;
            display: flex;
            align-items: center;
            width: 310px;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #D9D9D9;
            padding: 0px 12px;
            color: #7a7a7a;
            font-family: "Walkway-Black";
            font-size: 12px;
            letter-spacing: 1.2px;
            &::placeholder {
              color: #d0d0d0;
            }
          }
          .email-submit {
            position: absolute;
            right: 12px;
            outline: none;
            border: none;
            padding: 5px 12px;
            border-radius: 4px;
            background: #D9D9D9;
            color: #7A7A7A;
            text-align: center;
            font-family: 'Walkway-Black';
            font-size: 12px;
            letter-spacing: 1.2px;
          }
        }
        .colorverified {
          font-size: 12px;
        }
        h5 {
          font-family: "Walkway-Black";
          margin: 8px 0;
          color: #7a7a7a;
          letter-spacing: 1.28px;
          font-size: 10px;
        }
      //   .icon {
      //     display: flex;
      //     gap: 13px;
      //     img {
      //       width: 18px;
      //       height: 21px;
      //     }
      //   }
      }
    }
  }
}
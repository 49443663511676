.container {
  background: transparent;
  display: flex;
  //   padding: 50px 0;
  row-gap: 30px;
  flex-direction: column;
  align-items: center;
  .circle-progress {
    position: relative;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: conic-gradient(#7a7a7a 3.6deg, #ededed 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background: #fff;
    }
    .circle-value {
      position: relative;
      font-size: 12px;
      font-weight: 600;
      color: #7a7a7a;
    }
  }
}

@media screen and (max-width: 430px) {
  .container {
    opacity: 0.8;
    .circle-progress {
      height: 20px;
      width: 20px;
      &::before {
        height: 14px;
        width: 14px;
      }
      .circle-value {
        font-size: 8px;
      }
    }
  }

}
.Logo-wrapper {
    opacity: 1;
    color: #461f09;
    overflow: hidden;
    font-family: "DMSerifDisplay-Regular";
    transition: opacity 0.7s ease-out;
    .boththewords {
        display: flex;
        gap: 0;
        align-items: baseline;
        overflow: hidden;
        .greyish {
            font-family: "DMSerifDisplay-Regular";
            font-size: 26px;
            letter-spacing: 0;
        }
        .goat-us {
            font-family: "DMSerifDisplay-Regular";
            color: #461f09;
            font-size: 32px;
            letter-spacing: 0;
        }
    }
    // &:hover {
    //     opacity: 1;
    //     .boththewords{
    //         .greyish {
    //             color: #3c3c3c;
    //         }
    //     }
    // }
}
@media screen and (max-width: 430px) {
    .Logo-wrapper {
        .boththewords {
            .greyish {
                font-size: 22px;
            }
            .goat-us {
                font-size: 28px;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .Logo-wrapper {
        .boththewords {
            .greyish {
                font-size: 18px;
            }
            .goat-us {
                font-size: 24px;
            }
        }
    }
  }
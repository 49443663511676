.work-wrapper {
    width: 100%;
    aspect-ratio: 16/13;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    
    .logo {
        position: relative;
        a {
          text-decoration: none;
          background: transparent;
          width: max-content;
          opacity: 0.5;
          transition: opacity 0.3s ease-in-out;
          .logo1 {
            background: transparent;
            list-style: none;
            position: relative;
            transition: all 1.2s ease-in;
            margin-left: 2rem;
            width: max-content;
            overflow: hidden;
            position: relative;
          }
          &:hover {
            opacity: 1;
          }
        }
    }
    .navbar-content {
        padding-right: 16px;
        .nav {
            transition: color 0.72s ease-in-out;
            &:hover {
                color: black;
            }
            a {
                color: #d9d9d9;
                text-decoration: none;
                &:hover {
                    color: black;
                }
            }
            .work-link {
                color: #000;
            }
        }
    }
    .about-wrapper {
        position: relative;
        a {
            text-decoration: none;
            .work-box {
                width: 100%;
                aspect-ratio: 16/9;
                border-radius: 4px;
                border: 1px solid #5835214d;
                align-items: center;
                position: relative;
                overflow: hidden;
                margin-top: 16px;
                display: grid;
                grid-template-columns: 1fr 4fr;
                .box1 {
                    height: 100%;
                    position: relative !important;
                    overflow: hidden;
                    .rotator {
                        position: absolute;
                        left: -3450px;
                        width: max-content;
                        transform-origin: center;
                        transform: rotate(-90deg);
                        // border: 1px solid rosybrown;
                        background: transparent;
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        overflow: hidden;
                        .services {
                            background: transparent;
                            margin-top: 12px;
                            .text {
                                white-space: nowrap;
                                background: transparent;
                                color: #C0C0C0;
                                overflow: hidden;
                                .text1 {
                                    background: transparent;
                                    animation: slide1 24s infinite linear;
                                    display: inline-block;
                                    text-align: right;
                                    font-family: "Walkway-Bold";
                                    font-size: 26px;
                                    font-weight: 400;
                                    font-style: normal;
                                    text-transform: uppercase;
                                    letter-spacing: 2.7px;
                                }
                            }
                        }
                        .testimonials {
                            background: transparent;
                            margin-bottom: 20px;
                            .text {
                                white-space: nowrap;
                                background: transparent;
                                color: #E4E4E4;
                                overflow: hidden;
                                .text1 {
                                    background: transparent;
                                    animation: slide2 20s linear infinite;
                                    display: inline-block;
                                    text-align: right;
                                    font-family: "Walkway-Black";
                                    font-size: 52px;
                                    font-weight: 400;
                                    font-style: normal;
                                    text-transform: uppercase;
                                    letter-spacing: 4px;
                                }
                            }
                        }
                    }
                }
                .box2 {
                    width: 100%;
                    height: 100%;
                    z-index: 3;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0 32px 0 16px;
                    video {
                        width: 100%;
                        object-fit: cover;
                        border-radius: 4px;
                    }
                }
                .box3 {
                    display: none;
                }
            }
        }
    }
    .request {
        padding-top: 10px;
        text-align: center;
        letter-spacing: 1.5px;
        color: #c9c9c9;
        font-family: "Walkway-Black";
        font-size: 8px;
    }
    .request1 {
        background: transparent;
    }
}

@keyframes slide1 {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
@keyframes slide2 {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

@media screen and (max-width: 430px) {
    .work-wrapper {
        height: 70dvh;
        justify-content: center;

        .header-wrapper {
            .navbar-content {
                .nav {
                    p {
                        font-size: 0.8rem;
                    }
                }
            }
        }

        .about-wrapper {
            height: 100%;
            a {
                .work-box {
                    height: 90%;
                    margin-top: 6px;
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr 2fr 1fr;
                    .box1 {
                        .rotator {
                            position: relative;
                            transform: rotate(0deg);
                            .desktop-testimonials {
                                display: none;
                            }
                        }
                    }
                    .mob-box {
                        display: grid;
                        align-items: end;
                        .rotator {
                            .services {
                                margin-top: 0;
                                padding-bottom: 12px;
                                .text {
                                    .text1 {
                                        font-size: 30px;
                                    }
                                }
                            }
                        }
                    }
                    .box2 {
                        padding: 0 4px;
                    }
                    .box3 {
                        display: block;
                    }
                }
            }
        }
        .request {
            padding-top: 8px;
            letter-spacing: 1px;
            font-size: 7px;
        }
    }
}

@media screen and (max-width: 768px) {
    .work-wrapper {
        .cursor {
            display: none;
        }
    }
}